import React from 'react';

const SvgNone = props => (
    <svg width="1em" height="1em" viewBox="0 0 14 14" {...props}>
        <path
            d="M7 1a6 6 0 106 6 6.006 6.006 0 00-6-6zm0 1.5c.934 0 1.844.292 2.603.836L3.336 9.603A4.493 4.493 0 017 2.5zm0 9a4.471 4.471 0 01-2.603-.836l6.267-6.267A4.493 4.493 0 017 11.5z"
            fill="#000"
            fillRule="evenodd"
        />
    </svg>
);

export default SvgNone;

import React from 'react';

const SvgMove = props => (
    <svg width="1em" height="1em" viewBox="0 0 12 12" {...props}>
        <path
            d="M9.75 5.25h-3v-3h1.5L6 0 3.75 2.25h1.5v3h-3v-1.5L0 6l2.25 2.25v-1.5h3v3h-1.5L6 12l2.25-2.25h-1.5v-3h3v1.5L12 6 9.75 3.75z"
            fill="#111"
            fillRule="evenodd"
        />
    </svg>
);

export default SvgMove;

import React from 'react';

const SvgUnlock = props => (
    <svg width="1em" height="1em" viewBox="0 0 14 14" {...props}>
        <g fill="none" fillRule="evenodd">
            <path d="M0 0h14v14H0z" />
            <path
                d="M7 4.667c-.911 0-1.764.232-2.5.63V3.5c0-1.287 1.121-2.333 2.5-2.333S9.5 2.213 9.5 3.5h1.25C10.75 1.57 9.068 0 7 0S3.25 1.57 3.25 3.5v2.757C2.474 7.079 2 8.154 2 9.333 2 11.906 4.243 14 7 14s5-2.094 5-4.667-2.243-4.666-5-4.666zm.625 5.726v1.274h-1.25v-1.274c-.726-.242-1.25-.884-1.25-1.643C5.125 7.785 5.966 7 7 7s1.875.785 1.875 1.75c0 .76-.524 1.401-1.25 1.643z"
                fill="#C8C8C8"
            />
        </g>
    </svg>
);

export default SvgUnlock;

import React from 'react';

const SvgImage = props => (
    <svg width="1em" height="1em" viewBox="0 0 20 20" {...props}>
        <path
            d="M16.667 1.667H3.333V.833c0-.46.373-.833.834-.833h11.666c.46 0 .834.373.834.833v.834zm2.5 1.666c.46 0 .833.373.833.834v15c0 .46-.373.833-.833.833H.833A.833.833 0 010 19.167v-15c0-.46.373-.834.833-.834h18.334zM5.833 6.667a1.667 1.667 0 100 3.333 1.667 1.667 0 000-3.333zm-1.666 9.166h12.5l-4.167-7.5-8.333 7.5z"
            fill="#B96DD7"
            fillRule="evenodd"
        />
    </svg>
);

export default SvgImage;

import React from 'react';

const SvgUrl = props => (
    <svg width="1em" height="1em" viewBox="0 0 14 14" {...props}>
        <g fill="#C8C8C8" fillRule="nonzero">
            <path d="M7.133 6.867l-.707-.707L7.84 4.746l.707.707a3.97 3.97 0 010 5.674l-1.68 1.68a3.97 3.97 0 01-5.674 0c-1.59-1.59-1.59-4.084.014-5.688L3.43 4.986 4.814 6.43l-.721.692-1.486 1.426c-.81.81-.81 2.036 0 2.846s2.036.81 2.846 0l1.68-1.68c.81-.81.81-2.036 0-2.846z" />
            <path d="M6.867 7.133l.707.707L6.16 9.254l-.707-.707a3.97 3.97 0 010-5.674l1.68-1.68a3.97 3.97 0 015.674 0c1.59 1.59 1.59 4.084-.014 5.688L10.57 9.014 9.186 7.57l.721-.692 1.486-1.426c.81-.81.81-2.036 0-2.846s-2.036-.81-2.846 0l-1.68 1.68c-.81.81-.81 2.036 0 2.846z" />
        </g>
    </svg>
);

export default SvgUrl;

import React from 'react';

const SvgTeam = props => (
    <svg width="1em" height="1em" viewBox="0 0 14 14" {...props}>
        <path
            d="M13.01 8.9c.582.143.99.657.99 1.247v2.424a.432.432 0 01-.436.429H9.561c.052-.137.079-.282.08-.429v-2.03a2.157 2.157 0 00-1.58-2.062l-.12-.034c.31-.173.536-.463.624-.803.088-.34.03-.7-.158-.997a3.584 3.584 0 01-.51-2.106A3.57 3.57 0 007 2.195a2.627 2.627 0 012.286-1.194c1.438.08 2.557 1.263 2.535 2.68v1.177a2.57 2.57 0 01-1.528 2.336l.166.826a.432.432 0 00.322.332l2.229.548zm-5.992.406c.507.157.857.661.857 1.235v2.03c0 .237-.176.429-.394.429H.394C.176 13 0 12.808 0 12.572v-2.03c0-.576.352-1.08.86-1.236l1.699-.528a.41.41 0 00.266-.279l.134-.447a3.62 3.62 0 01-1.812-.343.423.423 0 01-.199-.268.461.461 0 01.047-.341c.438-.805.64-1.737.58-2.67 0-.7.261-1.37.725-1.855A2.259 2.259 0 014.03 1.86C5.322 1.956 6.32 3.134 6.3 4.542A4.8 4.8 0 006.882 7.1a.46.46 0 01.048.341.423.423 0 01-.2.269 3.62 3.62 0 01-1.81.343l.133.447c.041.135.14.24.267.279l1.698.528z"
            fill="#4FCA33"
            fillRule="nonzero"
        />
    </svg>
);

export default SvgTeam;

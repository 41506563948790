import React from 'react';

const SvgPassword = props => (
    <svg width="1em" height="1em" viewBox="0 0 14 14" {...props}>
        <g fill="none" fillRule="evenodd">
            <path d="M0 0h14v14H0z" />
            <path
                d="M10.75 6.257V3.5C10.75 1.57 9.068 0 7 0S3.25 1.57 3.25 3.5v2.757C2.474 7.079 2 8.154 2 9.333 2 11.906 4.243 14 7 14s5-2.094 5-4.667c0-1.179-.474-2.254-1.25-3.076zm-3.194 4.378V12H6.444v-1.365c-.645-.259-1.11-.946-1.11-1.76C5.333 7.841 6.08 7 7 7c.919 0 1.667.841 1.667 1.875 0 .814-.466 1.501-1.111 1.76zM9.778 5A6.508 6.508 0 007 4.389c-1.013 0-1.96.225-2.778.611V3.26C4.222 2.012 5.468 1 7 1s2.778 1.013 2.778 2.26V5z"
                fill="#C8C8C8"
            />
        </g>
    </svg>
);

export default SvgPassword;

import React from 'react';

const SvgTwitter = props => (
    <svg width="1em" height="1em" viewBox="0 0 14 14" {...props}>
        <path
            d="M14 3.27c-.525.253-1.05.338-1.662.422.612-.338 1.05-.846 1.225-1.523-.526.339-1.138.508-1.838.677A3.109 3.109 0 009.625 2c-1.488 0-2.8 1.27-2.8 2.792 0 .254 0 .423.087.593C4.55 5.3 2.362 4.2.962 2.508c-.262.423-.35.846-.35 1.438 0 .93.525 1.777 1.313 2.285-.438 0-.875-.17-1.313-.339 0 1.354.963 2.454 2.276 2.708-.263.085-.526.085-.788.085-.175 0-.35 0-.525-.085.35 1.1 1.4 1.946 2.712 1.946-.962.762-2.187 1.185-3.587 1.185H0C1.313 12.492 2.8 13 4.375 13c5.25 0 8.137-4.23 8.137-7.87v-.338c.613-.423 1.138-.93 1.488-1.523z"
            fill="#1C94E0"
            fillRule="nonzero"
        />
    </svg>
);

export default SvgTwitter;

import React from 'react';

const SvgEdit = props => (
    <svg width="1em" height="1em" viewBox="0 0 14 14" {...props}>
        <path
            d="M10 11V9h2v3a1 1 0 01-1 1H2a1 1 0 01-1-1V3a1 1 0 011-1h3v2H3v7h7zm2.581-9.587l.006.006a1.409 1.409 0 010 1.994L8 8 5 9l1-3 4.587-4.587a1.409 1.409 0 011.994 0z"
            fill="#C8C8C8"
            fillRule="evenodd"
        />
    </svg>
);

export default SvgEdit;

import React from 'react';

const SvgRotate = props => (
    <svg width="1em" height="1em" viewBox="0 0 12 11" {...props}>
        <path
            d="M1.866 8.981A5.414 5.414 0 01.727 6.493l1.6-.26c.112.645.392 1.26.813 1.775l-1.274.973zM5.38 11a5.786 5.786 0 01-2.413-.989l.962-1.266c.506.361 1.1.605 1.72.706L5.378 11zm1.89 0L7 9.45a4.112 4.112 0 001.791-.757l.989 1.246A5.766 5.766 0 017.269 11zM6.324.079C9.454.079 12 2.545 12 5.577A5.354 5.354 0 0110.925 8.8L9.61 7.877a3.814 3.814 0 00.767-2.3c0-2.165-1.818-3.927-4.054-3.927A4.14 4.14 0 003.458 2.8L5.19 4.478 0 5.028.568 0l1.744 1.69A5.79 5.79 0 016.324.08z"
            fill="#111"
            fillRule="evenodd"
        />
    </svg>
);

export default SvgRotate;

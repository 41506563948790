import React from 'react';

const SvgDeleteCircle = props => (
    <svg width="1em" height="1em" viewBox="0 0 18 18" {...props}>
        <g fill="none" fillRule="evenodd">
            <circle fill="#FFF" cx={9} cy={9} r={9} />
            <path
                d="M12.766 5.234a.799.799 0 00-1.13 0L9.005 7.865 6.374 5.234a.799.799 0 00-1.13 1.13l2.631 2.631-2.631 2.631a.799.799 0 101.13 1.13l2.631-2.631 2.631 2.631a.799.799 0 001.13-1.13l-2.631-2.631 2.631-2.631a.799.799 0 000-1.13z"
                fill="#000"
            />
        </g>
    </svg>
);

export default SvgDeleteCircle;

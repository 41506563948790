import React from 'react';

const SvgCustomizations = props => (
    <svg width="1em" height="1em" viewBox="0 0 14 14" {...props}>
        <path
            d="M11.9 2.742c-.642-.292-1.283-.467-1.983-.467-.934 0-1.75.292-2.509.525-.466.175-.933.292-1.283.292-.233 0-.35-.059-.408-.175 0-.059.058-.35.116-.525.175-.525.35-1.167-.116-1.809A1.671 1.671 0 004.433 0c-.525 0-.991.175-1.458.467C1.108 1.75 0 3.908 0 6.183c0 3.85 3.15 7 7 7 3.092 0 5.892-2.1 6.767-5.133.058-.35.875-3.967-1.867-5.308zM1.75 6.183a1.17 1.17 0 011.167-1.166 1.17 1.17 0 011.166 1.166A1.17 1.17 0 012.917 7.35 1.17 1.17 0 011.75 6.183zm2.625 4.084A1.17 1.17 0 013.208 9.1a1.17 1.17 0 011.167-1.167A1.17 1.17 0 015.542 9.1a1.17 1.17 0 01-1.167 1.167zm3.208 1.166a1.17 1.17 0 01-1.166-1.166A1.17 1.17 0 017.583 9.1a1.17 1.17 0 011.167 1.167 1.17 1.17 0 01-1.167 1.166zm2.334-3.5c-.992 0-1.75-.758-1.75-1.75 0-.991.758-1.75 1.75-1.75.991 0 1.75.759 1.75 1.75 0 .992-.759 1.75-1.75 1.75z"
            fill="#C8C8C8"
            fillRule="evenodd"
        />
    </svg>
);

export default SvgCustomizations;

import React from 'react';

const SvgThumb = props => (
    <svg width="1em" height="1em" viewBox="0 0 20 20" {...props}>
        <path
            d="M2.375 8.044c.437 0 .791.35.791.782V19H.792A.787.787 0 010 18.217v-9.39c0-.433.354-.783.792-.783h1.583zm14.947-.679a2.369 2.369 0 011.401 1.145c.295.55.357 1.194.172 1.79l-2.192 7.042A2.36 2.36 0 0114.431 19H4.75V9.17l3.166-3.757v-3.63c0-.214.088-.418.244-.566A.81.81 0 018.741 1c1.084.046 3.133.92 3.133 3.913v2.348h4.75c.237 0 .472.035.698.104z"
            fill="#C8C8C8"
            fillRule="evenodd"
        />
    </svg>
);

export default SvgThumb;

import React from 'react';

const SvgPrint = props => (
    <svg width="1em" height="1em" viewBox="0 0 20 20" {...props}>
        <g fill="none" fillRule="evenodd">
            <path fill="#000" d="M3 19h14v1H3z" />
            <path
                d="M17.5 19.5a2 2 0 002-2c0-.105.001-.196.004-.341.011-.492.005-.52-.014-.53-.18-.092-.68-.129-1.99-.129-1.1 0-1.698.107-1.919.271-.083.062-.097.127-.086.501.004.107.005.16.005.228 0 .76 1.077 2 2 2z"
                stroke="#000"
            />
            <rect fill="#FFF" x={11} y={11} width={8} height={8} rx={2} />
            <path fill="#000" d="M6 16h14v1H6z" />
            <path
                d="M4.507 16.992l.008-.492H15a1.5 1.5 0 001.5-1.5V2A1.5 1.5 0 0015 .5H2A1.5 1.5 0 00.5 2L.499 16.526c-.01.152-.01.283-.003.613.003.172.004.257.004.361a2 2 0 104 0c0-.104.001-.19.004-.36l.003-.148z"
                stroke="#000"
            />
            <path fill="#000" d="M4 11h9v1H4zM4 8h9v1H4zM4 5h9v1H4z" />
        </g>
    </svg>
);

export default SvgPrint;

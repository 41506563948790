import React from 'react';

const SvgArrowDown = props => (
    <svg width="1em" height="1em" viewBox="0 0 14 14" {...props}>
        <path
            d="M9.7 5H4.3a.302.302 0 00-.269.158.275.275 0 00.029.3l2.7 3.428A.305.305 0 007 9a.305.305 0 00.24-.114l2.7-3.429a.274.274 0 00.028-.299A.302.302 0 009.7 5z"
            fill="#F60"
            fillRule="nonzero"
        />
    </svg>
);

export default SvgArrowDown;

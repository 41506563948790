import React from 'react';

const SvgIntegration = props => (
    <svg width="1em" height="1em" viewBox="0 0 14 14" {...props}>
        <path
            d="M7.583 6.417h2.334V3.5L14 7l-4.083 3.5V7.583H4.083V10.5L0 7l4.083-3.5v2.917h2.334V.583a.583.583 0 111.166 0v5.834zM7 14a.583.583 0 01-.583-.583V8.75h1.166v4.667A.583.583 0 017 14z"
            fill="#C8C8C8"
            fillRule="evenodd"
        />
    </svg>
);

export default SvgIntegration;

import React from 'react';

const SvgPayment = props => (
    <svg width="1em" height="1em" viewBox="0 0 14 14" {...props}>
        <g stroke="#C8C8C8" fill="none" fillRule="evenodd" strokeLinecap="square">
            <path d="M1 3.294h12M1 5.588h12M5.364 9.898H2.09C1.488 9.898 1 9.401 1 8.786V2.112C1 1.498 1.488 1 2.09 1H11.91C12.512 1 13 1.498 13 2.112v5.562M3.25 7.882h2.727M7.75 9.412h4.364v3.893H7.75z" />
            <path d="M8.5 9.342V8.23c0-.612.49-1.112 1.09-1.112.6 0 1.092.5 1.092 1.112v1.112M10 10.941v.556" />
        </g>
    </svg>
);

export default SvgPayment;

import React from 'react';

const SvgError = props => (
    <svg width="1em" height="1em" viewBox="0 0 2 12" {...props}>
        <g fill="#FF001F" fillRule="evenodd">
            <path d="M0 0h2v8H0zM0 10h2v2H0z" />
        </g>
    </svg>
);

export default SvgError;

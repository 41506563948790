import React from 'react';

const SvgBilling = props => (
    <svg width="1em" height="1em" viewBox="0 0 14 14" {...props}>
        <path
            d="M12.142.001H1.858a.8.8 0 00-.62.241A.837.837 0 001 .877v13.125l2.571-1.75 1.714 1.75L7 12.252l1.714 1.75 1.714-1.75 2.57 1.75V.877a.837.837 0 00-.235-.635.8.8 0 00-.621-.24zM7 8.751H3.572v-1.75H7v1.75zm0-3.5H3.572v-1.75H7v1.75zm2.57 3.5a.866.866 0 01-.856-.874c0-.484.384-.875.857-.875s.857.391.857.875a.866.866 0 01-.857.875zm0-3.5a.866.866 0 01-.856-.874c0-.484.384-.875.857-.875s.857.391.857.875a.866.866 0 01-.857.875z"
            fill="#C8C8C8"
            fillRule="evenodd"
        />
    </svg>
);

export default SvgBilling;

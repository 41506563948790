import React from 'react';

const SvgApparel = props => (
    <svg width="1em" height="1em" viewBox="0 0 20 20" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                d="M6.536.5H2A1.5 1.5 0 00.5 2v12A1.5 1.5 0 002 15.5h16a1.5 1.5 0 001.5-1.5V2A1.5 1.5 0 0018 .5h-4.536a3.5 3.5 0 11-6.929 0z"
                stroke="#000"
            />
            <rect stroke="#000" x={3.5} y={8.5} width={13} height={11} rx={1} />
            <path fill="#FFF" d="M4 8h12v1H4zM4 15h12v1H4z" />
        </g>
    </svg>
);

export default SvgApparel;

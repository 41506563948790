import React from 'react';

const SvgQuestion = props => (
    <svg width="1em" height="1em" viewBox="0 0 14 14" {...props}>
        <path
            d="M7 0C3.15 0 0 3.15 0 7s3.15 7 7 7 7-3.15 7-7-3.15-7-7-7zm0 11.375c-.525 0-.875-.35-.875-.875s.35-.875.875-.875.875.35.875.875-.35.875-.875.875zM8.313 7.35c-.438.263-.438.35-.438.525v.875h-1.75v-.875c0-1.138.7-1.662 1.225-2.013.438-.262.525-.35.525-.612 0-.525-.35-.875-.875-.875-.35 0-.612.175-.787.438l-.438.787-1.488-.875.438-.787C5.163 3.15 6.038 2.625 7 2.625c1.488 0 2.625 1.138 2.625 2.625 0 1.225-.787 1.75-1.313 2.1z"
            fill="#C8C8C8"
            fillRule="evenodd"
        />
    </svg>
);

export default SvgQuestion;

import React from 'react';

const SvgDownload = props => (
    <svg width="1em" height="1em" viewBox="0 0 20 20" {...props}>
        <path
            d="M19.167 3.333c.46 0 .833.373.833.834v15c0 .46-.372.833-.833.833H.833A.833.833 0 010 19.167V.833C0 .373.372 0 .833 0H7.5c.262 0 .51.123.667.333l2.25 3h8.75zm-5.834 7.155l-2.5 2.5V6.666H9.167v6.322l-2.5-2.5-1.179 1.178 3.923 3.923a.832.832 0 001.178 0l3.923-3.923-1.179-1.178z"
            fill="#00BAFF"
            fillRule="nonzero"
        />
    </svg>
);

export default SvgDownload;

import React from 'react';

const SvgDribbble = props => (
    <svg width="1em" height="1em" viewBox="0 0 14 14" {...props}>
        <path
            d="M7 0C3.15 0 0 3.15 0 7s3.15 7 7 7 7-3.15 7-7-3.15-7-7-7zm1.925 6.475s-.175-.525-.263-.7c1.488-.7 2.45-1.488 2.8-1.925.613.788.963 1.75 1.05 2.8-.7-.175-2.274-.438-3.587-.175zm-.963-2.012c-.7-1.226-1.487-2.276-1.837-2.888 1.662-.438 3.15.088 4.287 1.137-.35.438-1.05 1.138-2.45 1.75zm-3.5-2.276c.35.438 1.05 1.488 1.75 2.8a21.508 21.508 0 01-4.462.526c.35-1.4 1.4-2.625 2.713-3.325zM7 6.388c.088.176.175.35.263.438-2.45.787-4.026 2.8-4.55 3.587-.7-.962-1.138-2.1-1.138-3.237C2.362 7.088 4.638 7 7 6.388zm.875 1.926c.613 1.75.963 3.15 1.05 3.85-1.838.612-3.938.262-5.075-.7.35-.613 1.488-2.188 4.025-3.15zm2.537 2.974c-.175-.7-.437-1.925-.962-3.412.963-.175 2.363 0 2.888.175a5.597 5.597 0 01-1.925 3.237z"
            fill="#EA4C89"
            fillRule="evenodd"
        />
    </svg>
);

export default SvgDribbble;

import React from 'react';

const SvgPreferences = props => (
    <svg width="1em" height="1em" viewBox="0 0 20 20" {...props}>
        <path
            d="M4.167 9.167H2.5V.833a.834.834 0 011.667 0v8.334zM10.833 2.5H9.167V.833a.834.834 0 011.666 0V2.5zM17.5 9.167h-1.667V.833a.834.834 0 011.667 0v8.334zM3.333 10.833a3.337 3.337 0 013.334 3.334 3.33 3.33 0 01-2.5 3.215v1.785a.834.834 0 01-1.667 0v-1.785A3.33 3.33 0 010 14.167a3.337 3.337 0 013.333-3.334zm10-3.333a3.33 3.33 0 01-2.5 3.215v8.452a.834.834 0 01-1.666 0v-8.452a3.33 3.33 0 01-2.5-3.215A3.337 3.337 0 0110 4.167 3.337 3.337 0 0113.333 7.5zM20 14.167a3.33 3.33 0 01-2.5 3.215v1.785a.834.834 0 01-1.667 0v-1.785a3.33 3.33 0 01-2.5-3.215 3.337 3.337 0 013.334-3.334A3.337 3.337 0 0120 14.167z"
            fill="#C8C8C8"
            fillRule="evenodd"
        />
    </svg>
);

export default SvgPreferences;

import styles from '../Login.module.scss';
import Icon from '../../../Base/Icons/Icon';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

const PasswordInput = (props) => {
    const [isPasswordShown, setPasswordShown] = useState(false);
    const handleShowPassword = (e) => {
        e.preventDefault();
        setPasswordShown(!isPasswordShown);
    };

    return (
        <>
            <div className={styles.inputIcon}>
                <Icon name="Password" size="sm" />
            </div>
            <input
                name={props.name ?? 'password'}
                type={isPasswordShown ? 'text' : 'password'}
                autoComplete={props.autoComplete}
                className={styles.inputLarge}
                placeholder={props.placeholder}
                value={props.value}
                onChange={props.onChange}
            />
            <button tabIndex="-1" className={styles.inputIconRight} type="button" onClick={handleShowPassword}>
                <Icon name="Eye" size="sm" color={isPasswordShown ? 'black' : 'grey'} />
            </button>
            <div className={styles.errorIcon}>
                <Icon name="Error" size="sm" />
            </div>
        </>
    );
};

export default PasswordInput;

PasswordInput.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    autoComplete: PropTypes.string
};

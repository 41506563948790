import React from 'react';

const SvgFrame = props => (
    <svg width="1em" height="1em" viewBox="0 0 20 20" {...props}>
        <path
            d="M17.001 17v-4H19v5.416c0 .473-.111.584-.584.584H13v-2h4.001zm-13.948.022H7V19H1.584C1.111 19 1 18.889 1 18.416V13h2.053v4.022zM17 3.037h-4V1h5.416c.473 0 .584.111.584.584V7h-2V3.037zm-13.978-.02V7H1V1.584C1 1.111 1.111 1 1.584 1H7v2.017H3.022zM6 5h8a1 1 0 011 1v8a1 1 0 01-1 1H6a1 1 0 01-1-1V6a1 1 0 011-1z"
            fill="#9BD0C3"
            fillRule="evenodd"
        />
    </svg>
);

export default SvgFrame;

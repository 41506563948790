import React from 'react';

const SvgTransparent = props => (
    <svg width="1em" height="1em" viewBox="0 0 20 20" {...props}>
        <path
            d="M1.75 1H7v6H1V1.75A.75.75 0 011.75 1zM7 7h6v6H7V7zm6-6h5.25a.75.75 0 01.75.75V7h-6V1zM1 13h6v6H1.75a.75.75 0 01-.75-.75V13zm12 0h6v5.25a.75.75 0 01-.75.75H13v-6z"
            fill="#B96DD7"
            fillRule="evenodd"
        />
    </svg>
);

export default SvgTransparent;

import React from 'react';

const SvgPremium = props => (
    <svg width="1em" height="1em" viewBox="0 0 14 14" {...props}>
        <path
            d="M12.412 3.99l-.982 1.603a1 1 0 01-1.706 0L7.761 2.386a1 1 0 00-1.706 0L4.092 5.593a1 1 0 01-1.706 0l-.981-1.604a.758.758 0 00-1.4.49l.788 6.298c0 .675.41 1.223.917 1.223h10.396c.507 0 .917-.548.917-1.223l.788-6.298a.758.758 0 00-1.4-.49z"
            fill="#F6C423"
            fillRule="nonzero"
        />
    </svg>
);

export default SvgPremium;

import React from 'react';

const SvgSearch = props => (
    <svg width="1em" height="1em" viewBox="0 0 14 14" {...props}>
        <path
            d="M10.459 9.306a5.639 5.639 0 001.153-3.459C11.612 2.635 9.059 0 5.847 0 2.635 0 0 2.635 0 5.847c0 3.212 2.635 5.847 5.847 5.847 1.318 0 2.553-.412 3.459-1.153l2.47 2.47a.89.89 0 00.577.248.89.89 0 00.576-.247.796.796 0 000-1.153l-2.47-2.553zm-4.612.659c-2.306 0-4.2-1.812-4.2-4.118s1.894-4.2 4.2-4.2 4.2 1.894 4.2 4.2-1.894 4.118-4.2 4.118z"
            fill="#C8C8C8"
            fillRule="evenodd"
        />
    </svg>
);

export default SvgSearch;

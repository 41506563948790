import React from 'react';

const SvgArrowLongLeft = props => (
    <svg width="1em" height="1em" viewBox="0 0 14 14" {...props}>
        <path
            d="M0 7.5L6.25 3 5 6.857h8.375c.346 0 .625.288.625.643 0 .355-.28.643-.625.643H5L6.25 12 0 7.5z"
            fill="#4FCA33"
            fillRule="nonzero"
        />
    </svg>
);

export default SvgArrowLongLeft;

import React from 'react';

const SvgBlog = props => (
    <svg width="1em" height="1em" viewBox="0 0 20 20" {...props}>
        <path
            d="M19.375 4.5h-.625V2.625A.625.625 0 0018.125 2c-4.802 0-7.19 1.467-8.128 2.257C9.05 3.47 6.646 2 1.875 2a.625.625 0 00-.625.625V4.5H.625A.625.625 0 000 5.125v13.75c0 .346.28.625.625.625h18.75c.345 0 .625-.28.625-.625V5.125a.625.625 0 00-.625-.625zM2.5 3.26c4.435.131 6.378 1.65 6.875 2.118v7.247h1.25V5.374c.485-.465 2.4-1.983 6.875-2.115v11.875c-4.357.121-6.593 1.497-7.497 2.248-.896-.755-3.117-2.128-7.503-2.248V3.259z"
            fill="#C8C8C8"
            fillRule="evenodd"
        />
    </svg>
);

export default SvgBlog;

import React from 'react';

const SvgClose = props => (
    <svg width="1em" height="1em" viewBox="0 0 20 20" {...props}>
        <path
            d="M10 19a9 9 0 110-18 9 9 0 010 18zm3.766-12.766a.799.799 0 00-1.13 0l-2.631 2.631-2.631-2.631a.799.799 0 00-1.13 1.13l2.631 2.631-2.631 2.631a.799.799 0 101.13 1.13l2.631-2.631 2.631 2.631a.799.799 0 001.13-1.13l-2.631-2.631 2.631-2.631a.799.799 0 000-1.13z"
            fill="#C8C8C8"
            fillRule="evenodd"
        />
    </svg>
);

export default SvgClose;

import React from 'react';

const SvgMenu = props => (
    <svg width="1em" height="1em" viewBox="0 0 14 14" {...props}>
        <g fill="#C8C8C8" fillRule="evenodd">
            <path d="M13.533 8H.467C.209 8 0 7.777 0 7.5S.21 7 .467 7h13.066c.258 0 .467.223.467.5s-.21.5-.467.5zM13.533 4H.467C.209 4 0 3.776 0 3.5S.21 3 .467 3h13.066c.258 0 .467.224.467.5s-.21.5-.467.5zM7.5 12h-7a.5.5 0 110-1h7a.5.5 0 110 1z" />
        </g>
    </svg>
);

export default SvgMenu;

import React from 'react';

const SvgTrash = props => (
    <svg width="1em" height="1em" viewBox="0 0 14 14" {...props}>
        <path
            d="M4 6h6v4.8c0 .66-.45 1.2-1 1.2H5c-.55 0-1-.54-1-1.2V6zm5.5-2.2H11V5H3V3.8h1.5V2.6c0-.36.25-.6.625-.6h3.75c.375 0 .625.24.625.6v1.2zm-1.25 0v-.6h-2.5v.6h2.5z"
            fill="red"
            fillRule="nonzero"
        />
    </svg>
);

export default SvgTrash;

import React from 'react';

const SvgPeople = props => (
    <svg width="1em" height="1em" viewBox="0 0 20 20" {...props}>
        <path
            d="M4.167 15.015c0-1.161.705-2.198 1.796-2.64 1.157-.47 2.515-.708 4.037-.708 1.522 0 2.88.238 4.037.709a2.845 2.845 0 011.796 2.64v1.65H4.167v-1.65zm6.25-4.182a3.75 3.75 0 110-7.5 3.75 3.75 0 010 7.5zm-8.75-9.166V5H0V.833C0 .373.372 0 .833 0H5v1.667H1.667zM19.167 0c.46 0 .833.372.833.833V5h-1.667V1.667H15V0h4.167zm-17.5 15v3.333H5V20H.833A.833.833 0 010 19.167V15h1.667zm16.666 3.333V15H20v4.167c0 .46-.372.833-.833.833H15v-1.667h3.333z"
            fill="#00BAFF"
            fillRule="nonzero"
        />
    </svg>
);

export default SvgPeople;

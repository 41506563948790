import React from 'react';

const SvgTeamBadgeLeft = props => (
    <svg width="1em" height="1em" viewBox="0 0 57 20" {...props}>
        <g fill="none" fillRule="evenodd">
            <g>
                <rect fill="#4FCA33" fillRule="nonzero" width={53} height={20} rx={2} />
                <path
                    d="M14.293 11.357c.415.102.707.47.707.89v1.733c0 .169-.14.306-.311.306h-2.86a.894.894 0 00.058-.306v-1.45a1.54 1.54 0 00-1.13-1.474l-.085-.024a.92.92 0 00.446-.573.905.905 0 00-.113-.713 2.56 2.56 0 01-.364-1.504A2.55 2.55 0 0010 6.568a1.877 1.877 0 011.633-.853c1.027.058 1.826.902 1.81 1.914v.84c0 .72-.427 1.371-1.091 1.67l.119.59a.308.308 0 00.23.237l1.592.391zm-4.28.29c.362.112.612.473.612.883v1.45c0 .169-.126.306-.281.306H5.28c-.155 0-.281-.137-.281-.306v-1.45c0-.411.251-.772.614-.883l1.214-.377a.293.293 0 00.19-.2l.095-.319a2.585 2.585 0 01-1.293-.245.302.302 0 01-.143-.191.33.33 0 01.034-.244c.313-.575.457-1.24.414-1.907 0-.5.187-.978.518-1.325a1.613 1.613 0 011.236-.511c.923.069 1.635.91 1.621 1.917-.032.64.112 1.276.416 1.826a.33.33 0 01.034.243.302.302 0 01-.142.192c-.407.193-.851.277-1.294.245l.096.32c.029.096.1.17.19.199l1.213.377zM20.09 7h5.61v1.06h-2.22V14H22.3V8.06h-2.21V7zm8.04 1.64c1.87 0 2.63 1.22 2.49 3.14H26.6c.18.82.81 1.33 1.65 1.33.58 0 1.11-.22 1.5-.62l.61.65c-.52.57-1.28.9-2.2.9-1.64 0-2.72-1.09-2.72-2.69 0-1.61 1.11-2.7 2.69-2.71zm-1.55 2.3h3.04c-.04-.86-.61-1.38-1.48-1.38-.84 0-1.44.53-1.56 1.38zM36.14 14h-1.13v-.65c-.38.47-.99.7-1.76.7-1.15 0-1.87-.71-1.87-1.65 0-.97.73-1.59 2.01-1.6H35v-.16c0-.67-.43-1.07-1.27-1.07-.51 0-1.04.18-1.57.54l-.47-.8c.74-.44 1.28-.67 2.29-.67 1.37 0 2.14.7 2.15 1.87l.01 3.49zm-2.65-.86c.82 0 1.45-.43 1.51-1.01v-.49h-1.44c-.75 0-1.1.2-1.1.71 0 .48.39.79 1.03.79zm11.13-4.5c1.22 0 1.95.78 1.95 2.05V14h-1.15v-2.99c0-.81-.48-1.3-1.26-1.3-.91.03-1.46.73-1.46 1.66V14h-1.15v-2.99c0-.81-.47-1.3-1.25-1.3-.92.03-1.48.73-1.48 1.66V14h-1.14V8.68h1.14v1.03c.37-.75 1.05-1.07 1.93-1.07.95 0 1.6.47 1.85 1.28.34-.89 1.05-1.28 2.02-1.28z"
                    fill="#FFF"
                />
            </g>
            <path
                d="M51 6.757v6.486a1 1 0 001.707.707l3.243-3.243a1 1 0 000-1.414L52.707 6.05A1 1 0 0051 6.757z"
                fill="#4FCA33"
                fillRule="nonzero"
            />
        </g>
    </svg>
);

export default SvgTeamBadgeLeft;

import React from 'react';

const SvgDigital = props => (
    <svg width="1em" height="1em" viewBox="0 0 20 20" {...props}>
        <g fill="none" fillRule="evenodd">
            <rect stroke="#000" x={0.5} y={1.5} width={19} height={13} rx={1} />
            <path fill="#000" d="M4 16h12v1H4z" />
            <rect stroke="#000" fill="#FFF" x={3.5} y={5.5} width={8} height={13} rx={1} />
            <path fill="#000" d="M7 16h1v1H7z" />
        </g>
    </svg>
);

export default SvgDigital;

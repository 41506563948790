import React from 'react';

const SvgEye = (props) => (
    <svg width="14" height="14" viewBox="0 0 14 14" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.3545 7.18208C13.2469 7.01128 10.6799 3 6.72014 3C2.76038 3 0.193343 7.01128 0.0858232 7.18208C-0.0286077 7.36421 -0.0286077 7.59579 0.0858232 7.77792C0.193343 7.94872 2.76038 11.96 6.72014 11.96C10.6799 11.96 13.2469 7.94872 13.3545 7.77792C13.4689 7.59579 13.4689 7.36421 13.3545 7.18208ZM6.72014 9.72C5.48303 9.72 4.48014 8.71712 4.48014 7.48C4.48014 6.24288 5.48303 5.24 6.72014 5.24C7.95726 5.24 8.96014 6.24288 8.96014 7.48C8.96014 8.07408 8.72414 8.64384 8.30406 9.06392C7.88398 9.484 7.31423 9.72 6.72014 9.72Z"
            fill="#A7A7A7"
        />
    </svg>
);

export default SvgEye;

import React from 'react';
import PropTypes from 'prop-types';
import styles from './Headline.module.scss';
import { SHORTER_LOCALES } from '../../../utils/languageHelpers';
import { injectIntl } from 'react-intl';

const Headline = props => {
    const isSmallerHeadline = SHORTER_LOCALES.indexOf(props.intl.locale) === -1;
    let titleClassName = `page-headline-sm`;
    let subtitleClassName = `page-headline-lg ${styles.subtitle}`;
    if (props.design === 'blue') {
        titleClassName = `page-headline-sm ${styles.titleBlue}`;
        subtitleClassName = `page-headline-lg ${styles.subtitleBlue}`;
    }
    if (props.design === 'inverse') {
        titleClassName = `page-headline-sm ${styles.titleInverse}`;
        subtitleClassName = `page-headline-lg ${styles.subtitleInverse}`;
    }
    return (
        <>
            <h3 className={titleClassName}>{props.title}</h3>
            <h1
                className={`${subtitleClassName} ${
                    isSmallerHeadline ? `page-headline-lg-smaller` : ''
                }`}
            >
                {props.subtitle}
            </h1>
        </>
    );
};

export default injectIntl(Headline);

Headline.propTypes = {
    subtitle: PropTypes.node,
    title: PropTypes.node,
    design: PropTypes.oneOf(['default', 'blue', 'inverse'])
};

import React from 'react';

const SvgFacebook = props => (
    <svg width="1em" height="1em" viewBox="0 0 14 14" {...props}>
        <path
            d="M13.387 0H.614A.598.598 0 000 .613v12.862c0 .263.263.525.613.525H7V9.625H5.25V7H7V5.25c0-1.837 1.05-2.625 2.625-2.625h1.75V5.25H10.5c-.525 0-.875.35-.875.875V7H11.9l-.525 2.625h-1.75V14h3.762c.35 0 .613-.262.613-.613V.614A.598.598 0 0013.387 0z"
            fill="#4267B2"
            fillRule="nonzero"
        />
    </svg>
);

export default SvgFacebook;

import React from 'react';

const SvgInactive = props => (
    <svg width="1em" height="1em" viewBox="0 0 14 14" {...props}>
        <path
            d="M10.5 6.217c0 1.722 1.5 2.14 1.5 3.13 0 .888-1.95 1.566-5 1.566s-5-.678-5-1.565c0-.991 1.5-1.409 1.5-3.13V4.651C3.5 2.617 5.05 1 7 1s3.5 1.617 3.5 3.652v1.565zM7 11.957c.65 0 1.2-.053 1.75-.105C8.45 12.53 7.75 13 7 13s-1.4-.47-1.75-1.148c.55.052 1.1.105 1.75.105z"
            fill="#C8C8C8"
            fillRule="evenodd"
        />
    </svg>
);

export default SvgInactive;

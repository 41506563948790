import React from 'react';

const SvgUser = props => (
    <svg width="1em" height="1em" viewBox="0 0 14 14" {...props}>
        <path
            d="M11.76 10.161l-3.124-.73V8.378c.978-.68 1.637-1.894 1.637-3.026V3.564c0-.841-.733-1.53-1.637-1.53h-.204l-.43-.803a.462.462 0 00-.561-.202l-2.574.962c-.63.235-1.14.94-1.14 1.573v1.788c0 1.132.66 2.346 1.637 3.026v1.053l-3.125.73c-.728.17-1.239.782-1.239 1.484V13h12v-1.355c0-.702-.511-1.313-1.24-1.484z"
            fill="#C8C8C8"
        />
    </svg>
);

export default SvgUser;

import React from 'react';

const SvgTransformVertical = props => (
    <svg width="1em" height="1em" viewBox="0 0 14 14" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                d="M3 8h6.959a1 1 0 0 1 .484 1.875l-5.958 3.302A1 1 0 0 1 3 12.302V8z"
                fill="#000"
            />
            <path
                d="M3 6h6.959a1 1 0 0 0 .484-1.875L4.485.823A1 1 0 0 0 3 1.698V6z"
                fill="#00BAFF"
            />
            <path fill="#FFF" d="M4 9h6l-6 3zM4 2v3h6z" />
        </g>
    </svg>
);

export default SvgTransformVertical;

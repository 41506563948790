import React from 'react';
import PropTypes from 'prop-types';
import styles from './Icons.module.scss';
import AlignBottom from './General/AlignBottom';
import AlignBottomLeft from './General/AlignBottomLeft';
import AlignTopLeft from './General/AlignTopLeft';
import AlignTopRight from './General/AlignTopRight';
import AlignBottomRight from './General/AlignBottomRight';
import AlignCenter from './General/AlignCenter';
import Apparel from './General/Apparel';
import ArrowDown from './General/ArrowDown';
import ArrowLongLeft from './General/ArrowLongLeft';
import Billing from './General/Billing';
import Blog from './General/Blog';
import Check from './General/Check';
import Close from './General/Close';
import Crop from './General/Crop';
import Color from './General/Color';
import Customizations from './General/Customizations';
import Digital from './General/Digital';
import DeleteCircle from './General/DeleteCircle';
import Download from './General/Download';
import Dribbble from './General/Dribbble';
import Edit from './General/Edit';
import Error from './General/Error';
import Eye from './General/Eye';
import Facebook from './General/Facebook';
import Frame from './General/Frame';
import FourToThree from './General/FourToThree';
import Google from './General/Google';
import Image from './General/Image';
import Inactive from './General/Inactive';
import Integration from './General/Integration';
import Instagram from './General/Instagram';
import Lock from './General/Lock';
import Linkedin from "./General/Linkedin";
import Mail from './General/Mail';
import Menu from './General/Menu';
import Minus from './General/Minus';
import Move from './General/Move';
import None from './General/None';
import OneToOne from './General/OneToOne';
import Payment from './General/Payment';
import Password from './General/Password';
import People from './General/People';
import Play from './General/Play';
import Play2 from './General/PlayVideo';
import Plus from './General/Plus';
import Premium from './General/Premium';
import Preferences from './General/Preferences';
import Pro from './General/Pro';
import Print from './General/Print';
import ProBadgeLeft from './General/ProBadgeLeft';
import ProBadgeRight from './General/ProBadgeRight';
import Rotate from './General/Rotate';
import Question from './General/Question';
import Search from './General/Search';
import SixttenToNine from './General/SixttenToNine';
import Thumb from './General/Thumb';
import Team from './General/Team';
import TeamBadgeLeft from './General/TeamBadgeLeft';
import TeamBadgeRight from './General/TeamBadgeRight';
import TransformVertical from './General/TransformVertical';
import Transparent from './General/Transparent';
import Trash from './General/Trash';
import Twitter from './General/Twitter';
import Unlock from './General/Unlock';
import Url from './General/Url';
import User from './General/User';

export function camelize(str) {
    str = str.replace('-', ' ');
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
        if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
        return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
}
export function capitalize(str) {
    return str[0].toUpperCase() + str.substring(1);
}

const Icon = (props) => {
    let width = 14;
    let height = 14;
    if (props.size === 'md') {
        width = 20;
    }
    if (props.width) {
        width = props.width;
    }
    if (props.height) {
        height = props.height;
    } else {
        height = width;
    }
    let className = props.className ? props.className : '';
    if (props.color) {
        const color = `color${capitalize(camelize(props.color))}`;
        className = styles[color];
    }

    switch (props.name) {
        case 'AlignBottom':
            return <AlignBottom width={width} height={height} className={className} {...props} />;
        case 'AlignBottomLeft':
            return (
                <AlignBottomLeft width={width} height={height} className={className} {...props} />
            );
        case 'AlignBottomRight':
            return (
                <AlignBottomRight width={width} height={height} className={className} {...props} />
            );
        case 'AlignTopLeft':
            return <AlignTopLeft width={width} height={height} className={className} {...props} />;
        case 'AlignTopRight':
            return <AlignTopRight width={width} height={height} className={className} {...props} />;
        case 'AlignCenter':
            return <AlignCenter width={width} height={height} className={className} {...props} />;
        case 'Apparel':
            return <Apparel width={width} height={height} className={className} {...props} />;
        case 'ArrowDown':
            return <ArrowDown width={width} height={height} className={className} {...props} />;
        case 'ArrowLongLeft':
            return <ArrowLongLeft width={width} height={height} className={className} {...props} />;
        case 'Billing':
            return <Billing width={width} height={height} className={className} {...props} />;
        case 'Blog':
            return <Blog width={width} height={height} className={className} {...props} />;
        case 'Check':
            return <Check width={width} height={height} className={className} {...props} />;
        case 'Close':
            return <Close width={width} height={height} className={className} {...props} />;
        case 'Color':
            return <Color width={width} height={height} className={className} {...props} />;
        case 'Crop':
            return <Crop width={width} height={height} className={className} {...props} />;
        case 'Customizations':
            return (
                <Customizations width={width} height={height} className={className} {...props} />
            );
        case 'DeleteCircle':
            return <DeleteCircle width={width} height={height} className={className} {...props} />;
        case 'Digital':
            return <Digital width={width} height={height} className={className} {...props} />;
        case 'Download':
            return <Download width={width} height={height} className={className} {...props} />;
        case 'Dribbble':
            return <Dribbble width={width} height={height} className={className} {...props} />;
        case 'Edit':
            return <Edit width={width} height={height} className={className} {...props} />;
        case 'Error':
            return <Error width={width} height={height} className={className} {...props} />;
        case 'Eye':
            return <Eye width={width} height={height} className={className} {...props} />;
        case 'Facebook':
            return <Facebook width={width} height={height} className={className} {...props} />;
        case 'Frame':
            return <Frame width={width} height={height} className={className} {...props} />;
        case 'FourToThree':
            return <FourToThree width={width} height={height} className={className} {...props} />;
        case 'Google':
            return <Google width={width} height={height} className={className} {...props} />;
        case 'Image':
            return <Image width={width} height={height} className={className} {...props} />;
        case 'Inactive':
            return <Inactive width={width} height={height} className={className} {...props} />;
        case 'Integration':
            return <Integration width={width} height={height} className={className} {...props} />;
        case 'Instagram':
            return <Instagram width={width} height={height} className={className} {...props} />;
        case 'Lock':
            return <Lock width={width} height={height} className={className} {...props} />;
        case 'Linkedin':
            return <Linkedin width={width} height={height} className={className} {...props} />;
        case 'Mail':
            return <Mail width={width} height={height} className={className} {...props} />;
        case 'Menu':
            return <Menu width={width} height={height} className={className} {...props} />;
        case 'Minus':
            return <Minus width={width} height={height} className={className} {...props} />;
        case 'Move':
            return <Move width={width} height={height} className={className} {...props} />;
        case 'Payment':
            return <Payment width={width} height={height} className={className} {...props} />;
        case 'Password':
            return <Password width={width} height={height} className={className} {...props} />;
        case 'People':
            return <People width={width} height={height} className={className} {...props} />;
        case 'Play':
            return <Play width={width} height={height} className={className} {...props} />;
        case 'Play2':
            return <Play2 width={width} height={height} className={className} {...props} />;
        case 'Plus':
            return <Plus width={width} height={height} className={className} {...props} />;
        case 'Premium':
            return <Premium width={width} height={height} className={className} {...props} />;
        case 'Preferences':
            return <Preferences width={width} height={height} className={className} {...props} />;
        case 'Print':
            return <Print width={width} height={height} className={className} {...props} />;
        case 'Pro':
            return <Pro width={width} height={height} className={className} {...props} />;
        case 'ProBadgeLeft':
            return <ProBadgeLeft width={width} height={height} className={className} {...props} />;
        case 'ProBadgeRight':
            return <ProBadgeRight width={width} height={height} className={className} {...props} />;
        case 'Rotate':
            return <Rotate width={width} height={height} className={className} {...props} />;
        case 'None':
            return <None width={width} height={height} className={className} {...props} />;
        case 'OneToOne':
            return <OneToOne width={width} height={height} className={className} {...props} />;
        case 'Question':
            return <Question width={width} height={height} className={className} {...props} />;
        case 'SixttenToNine':
            return <SixttenToNine width={width} height={height} className={className} {...props} />;
        case 'Search':
            return <Search width={width} height={height} className={className} {...props} />;
        case 'Thumb':
            return <Thumb width={width} height={height} className={className} {...props} />;
        case 'Team':
            return <Team width={width} height={height} className={className} {...props} />;
        case 'TeamBadgeLeft':
            return <TeamBadgeLeft width={width} height={height} className={className} {...props} />;
        case 'TeamBadgeRight':
            return (
                <TeamBadgeRight width={width} height={height} className={className} {...props} />
            );
        case 'Trash':
            return <Trash width={width} height={height} className={className} {...props} />;
        case 'TransformVertical':
            return (
                <TransformVertical width={width} height={height} className={className} {...props} />
            );
        case 'Transparent':
            return <Transparent width={width} height={height} className={className} {...props} />;
        case 'Twitter':
            return <Twitter width={width} height={height} className={className} {...props} />;
        case 'Unlock':
            return <Unlock width={width} height={height} className={className} {...props} />;
        case 'Url':
            return <Url width={width} height={height} className={className} {...props} />;
        case 'User':
            return <User width={width} height={height} className={className} {...props} />;

        default:
            return <div />;
    }
};

export default Icon;

Icon.propTypes = {
    name: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
    width: PropTypes.number,
    height: PropTypes.number,
    color: PropTypes.oneOf([
        'grey',
        'red',
        'blue',
        'light-blue',
        'white',
        'green',
        'orange',
        'black',
        'purple',
        'light-orange',
        'light-green'
    ])
};

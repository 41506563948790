import React from 'react';

const SvgCrop = props => (
    <svg width="1em" height="1em" viewBox="0 0 14 14" {...props}>
        <path
            d="M10.182 8.91h1.273V3.817c0-.7-.573-1.273-1.273-1.273H5.09v1.273h5.09V8.91zm-6.364 1.272V0H2.545v2.545H0v1.273h2.545v6.364c0 .7.573 1.273 1.273 1.273h6.364V14h1.273v-2.545H14v-1.273H3.818z"
            fill="#111"
            fillRule="nonzero"
        />
    </svg>
);

export default SvgCrop;

import React from 'react';

const SvgMail = props => (
    <svg width="1em" height="1em" viewBox="0 0 14 14" {...props}>
        <g fill="none" fillRule="evenodd">
            <path d="M0 0h14v14H0z" />
            <path
                d="M1.218 6.346l1.406 1.065L7.532 5.23l-3.805 3v3.68c0 .51.635.741.963.352l1.707-2.029 3.546 2.66c.321.24.783.064.862-.33l2.184-10.908a.546.546 0 00-.737-.614L1.343 5.403a.546.546 0 00-.125.943z"
                fill="#C8C8C8"
            />
        </g>
    </svg>
);

export default SvgMail;

import React from 'react';

const SvgOneToOne = props => (
    <svg width="1em" height="1em" viewBox="0 0 14 14" {...props}>
        <g stroke="#000" fill="none" fillRule="evenodd">
            <path d="M2.5 2.5h9v9h-9z" />
            <path
                fill="#FFF"
                d="M1.5 1.5h3v3h-3zM9.5 1.5h3v3h-3zM1.5 9.5h3v3h-3zM9.5 9.5h3v3h-3z"
            />
        </g>
    </svg>
);

export default SvgOneToOne;

import React from 'react';

const SvgAlignBottom = props => (
    <svg width="1em" height="1em" viewBox="0 0 14 14" {...props}>
        <g fill="none" fillRule="evenodd">
            <path fill="#000" d="M0 13v-1h14v1z" />
            <path fill="#00BAFF" fillRule="nonzero" d="M2 10V1h4v9z" />
            <path fill="#FFF" d="M3 9V2h2v7z" />
            <g>
                <path fill="#00BAFF" fillRule="nonzero" d="M8 10V5h4v5z" />
                <path fill="#FFF" d="M9 9V6h2v3z" />
            </g>
        </g>
    </svg>
);

export default SvgAlignBottom;

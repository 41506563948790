import React from 'react';

const SvgPlay = props => (
    <svg width="1em" height="1em" viewBox="0 0 20 20" {...props}>
        <g fill="none" fillRule="evenodd">
            <circle fill="#00BAFF" fillRule="nonzero" cx={10} cy={10} r={10} />
            <path fill="#FFF" d="M14 10l-6 4V6z" />
        </g>
    </svg>
);

export default SvgPlay;

import React from 'react';

const SvgInstagram = props => (
    <svg width="1em" height="1em" viewBox="0 0 14 14" {...props}>
        <path
            d="M10.627 4.245a.84.84 0 110-1.68.84.84 0 010 1.68zM7 10.5c-1.93 0-3.5-1.57-3.5-3.5S5.07 3.5 7 3.5s3.5 1.57 3.5 3.5-1.57 3.5-3.5 3.5zm0-5.25c-.965 0-1.75.785-1.75 1.75S6.035 8.75 7 8.75 8.75 7.965 8.75 7 7.965 5.25 7 5.25zM10.5 14h-7C1.701 14 0 12.299 0 10.5v-7C0 1.701 1.701 0 3.5 0h7C12.299 0 14 1.701 14 3.5v7c0 1.799-1.701 3.5-3.5 3.5zm-7-12.25c-.818 0-1.75.932-1.75 1.75v7c0 .834.916 1.75 1.75 1.75h7c.818 0 1.75-.932 1.75-1.75v-7c0-.818-.932-1.75-1.75-1.75h-7z"
            fill="#E1306C"
            fillRule="nonzero"
        />
    </svg>
);

export default SvgInstagram;

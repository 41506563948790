import React from 'react';

const SvgColor = props => (
    <svg width="1em" height="1em" viewBox="0 0 12 12" {...props}>
        <g fillRule="evenodd">
            <path d="M1.5 4.136l-.707.707a1 1 0 000 1.414l1.414 1.414L.479 9.4c-.513.512-.637 1.33-.23 1.93a1.502 1.502 0 002.312.23l1.768-1.768 1.413 1.415a1 1 0 001.415 0l.707-.707L1.5 4.136zM11.4 6.965a.5.5 0 000-.707l-1.061-1.061-2.122.707.707-2.121L5.744.6a.5.5 0 00-.708 0L2.208 3.43l6.364 6.364L11.4 6.965z" />
        </g>
    </svg>
);

export default SvgColor;

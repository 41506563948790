import React from 'react';

function SvgAlignCenter(props) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 14 14" {...props}>
            <g fill="none" fillRule="evenodd" stroke="#000">
                <path d="M1.5 2.5h11v9h-11z" />
                <path fill="#000" d="M5.5 5.5h3v3h-3z" />
                <path
                    fill="#FFF"
                    d="M.5 1.5h3v3h-3zM10.5 1.5h3v3h-3zM.5 9.5h3v3h-3zM10.5 9.5h3v3h-3z"
                />
            </g>
        </svg>
    );
}

export default SvgAlignCenter;
